.main-content {
  display: block;
  max-width: calc(1080px - 12rem);
  margin-top: 0;
  margin-left: 0;
  margin-right: 0;
  padding: 1rem;
  box-shadow: rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
  background-color: rgba(255, 255, 255, 1);
  line-height: 1.5rem;
  color: rgba(33, 33, 33, 1);
}

.main-header,
.main-footer {
  box-sizing: border-box;
}

.main-header {
  display: flex;
  position: sticky;
  height: 4rem;
  width: 100%;
  background-color: rgba(68, 76, 101, 1);
  box-shadow: rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
  justify-content: center;
}

.main-header .main-logo {
  max-width: calc(1080px - 12rem);
  height: 64px;
  display: flex;
  align-content: flex-start;
  align-items: center;
  line-height: 0;
}

.main-header .main-logo .logo, .logo-small {
  margin-left: 1rem;
  height: 24px;
  color: rgba(230, 233, 245, 1);
  font-size: 24px;
  font-weight: 600;
  line-height: 1.5rem;
  cursor: pointer;
  text-decoration: none;
}

.main-footer {
  max-width: calc(1080px - 12rem);
  margin: auto;
}

.main-footer p {
  margin-left: 1rem;
  margin-right: 1rem;
}

/* menu */
.header-container {
  display: flex;
  align-items: center;
  width: calc(100% - 2rem);
}

.menu-content {
  margin-right: 1rem;
}

.menu {
  position: relative;
}

.menu-btn {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: white;
  font-weight: 700;
  font-size: 0.875rem;
  cursor: pointer;
}

.nav-menu {
  position: absolute;
  background-color: white;
  margin: 0;
  padding: 0;
  list-style-type: none;
  right: 0;
  top: 2.7rem;
  border-radius: 0.5rem;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.40);
  padding: 0.25rem 0;
  width: max-content;
}

.nav-menu li {
  padding: 0.5rem 16px 0.5rem 16px;
  font-size: 0.875rem;
  font-weight: 700;
  text-decoration: none;
}

.nav-menu li a {
  text-decoration: none;
  color: #212121;
}

.nav-menu li a:visited {
  color: unset;
}

.hide {
  display: none;
}

.logo-small {
  display: none;
}

.external-url {
  display: flex;
  gap: 0.5rem;
}

.external::after {
  content: url('OPEN_IN_NEW_ICON');
  height: 1rem;
  width: 1rem;
  filter: invert(0%) sepia(0%) saturate(103%) hue-rotate(322deg) brightness(115%) contrast(74%);
}

@media screen and (max-width: 428px) {
  .logo {
    display: none;
  }
  
  .logo-small {
    display: flex;
    font-size: medium;
    align-items: center;
    line-height: 1rem;
  }
}

@media screen and (max-width: 256px) {
  .main-header .main-logo .logo, .menu-btn, .logo-small {
    font-size: small;
  }
}

@media screen and (max-width: 500px) {
  .nav-menu {
    width: calc(100vw - 2rem);
  }
}

@media screen and (max-width: 600px) {
  .main-header .main-logo .logo {
    font-size: medium;
    line-height: 1rem;
  }
}

@media screen and (min-width: 640px) {
  .main-content {
    padding: 2rem;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (max-width: 960px) {
  .github-corner {
    display: none !important;
  }
}

@media screen and (min-width: 960px) {
  .main-content {
    padding: 5rem;
  }

  .header-container {
    max-width: 55rem;
  }

  .menu-content {
    margin-right: none;
  }
}

@media screen and (min-width: 1142px) {
  .main-header .main-logo .logo {
    margin-left: 0;
  }
}
